/* =================== MENU =================== */
jQuery(document).ready(function($){
    function toggleMenu() {
    	var scrollTop = $(window).scrollTop();
        if (scrollTop > 100) {
            $('.header').addClass('active');
        } else {
            $('.header').removeClass('active');
        }
    }
    toggleMenu();
    $(window).on('scroll', debounce(function(){
        toggleMenu();        
    }, 10));
});
